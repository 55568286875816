import {
  List,
  Comment,
} from "antd";
import React from "react";
import { Teacher } from "../../types/teacher";

export interface TeacherNotesProps {
  teacher: Teacher;
}

export function TeacherNotes({ teacher }: TeacherNotesProps) {
  return (
    <List
      header={`${teacher.notes.length} отзывов`}
      itemLayout="horizontal"
      dataSource={teacher.comments}
      renderItem={(item) => (
        <li>
          <Comment
            author={item.author}
            avatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            content={<p>{item.content}</p>}
            datetime={"19 Dec 2020"}
          />
        </li>
      )}
    />
  );
}
