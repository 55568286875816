import React from "react";
import {
  Row,
  Col,
  Spin,
  Pagination,
  Layout,
  Tag,
  Divider,
} from "antd";
import { Teacher } from "../../types/teacher";
import { Gen } from "../../helpers";
import { FilterForm } from "./FilterForm";
import { TeacherCard } from "./TeacherCard";

const { Content } = Layout;

export function Teachers() {
  let teachers: Teacher[] = Gen.teachers();

  return (
    <div>
      <Content style={{ marginTop: 20 }}>
        <Spin spinning={false}>
          <Row>
            <Col span={20} offset={2}>
              <FilterForm />
            </Col>
          </Row>
          <Row>
            <Col span={20} offset={2}>
              <Row>
                <Divider />
              </Row>
              <Row>
                <Col>
                  <Pagination defaultCurrent={1} total={50} />
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <Tag>Преподавателей: <b>{teachers.length}</b></Tag>
                </Col>
              </Row>
              <Row>
                <Divider />
              </Row>
              <Row>
                {teachers.map((teacher) => (
                  <TeacherCard teacher={teacher} />
                ))}
              </Row>
              <Row>
                <Divider />
              </Row>
              <Row>
                <Pagination defaultCurrent={1} total={50} />
              </Row>
            </Col>
          </Row>
        </Spin>
      </Content>
    </div>
  );
}
