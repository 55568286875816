import React from "react";
import faker from "faker";
import {
  List,
  Avatar,
  Card,
  Button,
  Rate,
} from "antd";
import { Gen } from "../../helpers";
import { EduCenter } from "../../types/edu-center";
import { Link } from "react-router-dom";

interface CenterCardProps {
  center: EduCenter;
}

export function CenterCard({ center }: CenterCardProps) {
  let avatar = <Avatar src={Gen.loremFlickr(100, 100)} size={100} />;
  let title = <a href="https://ant.design">{center.title}</a>;
  return (
    <Link to={`/edu/${center.id}`}>
      <Card hoverable style={{ marginBottom: 10 }}>
        <List.Item>
          <List.Item.Meta
            avatar={avatar}
            title={title}
            description={center.description}
          />
        </List.Item>
        <span style={{ float: "left" }}>
          <Rate value={faker.random.number(5)} disabled />
        </span>
        <span style={{ float: "right" }}>
          <Button type="link">
            Преподаватели: &nbsp;
            <b>{faker.random.number({ min: 5, max: 50 })}</b>
          </Button>
        </span>
      </Card>
    </Link>
  );
}
