import React from "react";
import { Timeline } from "antd";
import { Teacher } from "../../types/teacher";

export interface TeacherHistoryProps {
  teacher: Teacher;
}

export function TeacherHistory({ teacher }: TeacherHistoryProps) {
  return (
    <Timeline mode="alternate">
      {teacher.timeline.map((item) => {
        return (
          <Timeline.Item color={item.color}>
            <b>{item.date}</b>
            <p>{item.contents}</p>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}
