import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Teachers } from "./Pages/Teachers/Teachers";
import { HeadMenu } from "./components/HeadMenu";
import { User } from "./Pages/User/User";
import { CenterPage } from "./Pages/CenterPage/CenterPage";
import { Centers } from "./Pages/Centers/Centers";
import { TeacherPage } from "./Pages/TeacherPage/TeacherPage";

const { Footer, Content } = Layout; //

let footerHeight = 140;

function App() {
  return (
    <div className="App">
      <Layout>
        <Router>
          <HeadMenu />
          <Content
            style={{ minHeight: document.body.clientHeight - footerHeight }}
          >
            <Switch>
              <Route exact path="/">
                <Teachers />
              </Route>
              <Route path="/edu/:id">
                <CenterPage />
              </Route>
              <Route path="/edu">
                <Centers />
              </Route>
              <Route path="/teacher/:id">
                <TeacherPage />
              </Route>
              <Route path="/user">
                <User />
              </Route>
            </Switch>
          </Content>
          <Footer>&copy; LLLL.kz 2020</Footer>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
