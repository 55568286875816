import React, { useState } from "react";
import faker from "faker";
import { Layout, Form, Input, InputNumber, Button, Avatar, Divider } from "antd";
import { Gen } from "../../../helpers";

const { Content } = Layout; //

const layout = {
  labelCol   : { span: 8 },
  wrapperCol : { span: 8 },
};

interface FormValues {
  user: {
    name: string;
    age: number;
    email: string;
    introduction: string;
    website: string;
  }
}

export function Profile() {
  let [profile, setProfile] = useState({
    name        : faker.name.firstName(),
    age         : faker.random.number(50),
    email       : faker.internet.email(),
    website     : faker.internet.url(),
    description : faker.lorem.paragraphs(),
  });

  const onFinish = (values: FormValues) => {
    console.log(values);
  };

  return (
    <Content style={{ padding: 10 }}>
      <Avatar src={Gen.loremFlickr(300, 300)} size={300} />
      <Divider />
      <Form<FormValues>
        {...layout}
        name="profile"
        onFinish={onFinish}
      >
        <Form.Item
          name={["user", "name"]}
          label="Name"
          rules={[{ required: true }]}
        >
          <Form.Item
            name={["user", "name"]}
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input
              value={profile.name}
              onChange={(v) => {
                profile.name = v.target.value;
                setProfile(profile);
              }}
            />
          </Form.Item>
          <Form.Item
            name={["user", "name"]}
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input
              value={profile.name}
              onChange={(v) => {
                profile.name = v.target.value;
                setProfile(profile);
              }}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name={["user", "email"]}
          label="Email"
          rules={[{ type: "email" }]}
        >
          <Input
            value={profile.email}
            onChange={(v) => {
              profile.email = v.target.value;
              setProfile(profile);
            }}
          />
        </Form.Item>
        <Form.Item
          name={["user", "age"]}
          label="Age"
          rules={[{ type: "number", min: 0, max: 99 }]}
        >
          <InputNumber
            value={profile.age}
            onChange={(v) => {
              profile.age = parseInt(v as string);
              setProfile(profile);
            }}
          />
        </Form.Item>
        <Form.Item name={["user", "website"]} label="Website">
          <Input
            value={profile.website}
            onChange={(v) => {
              profile.website = v.target.value;
              setProfile(profile);
            }}
          />
        </Form.Item>
        <Form.Item name={["user", "introduction"]} label="Introduction">
          <Input.TextArea
            value={profile.description}
            onChange={(v) => {
              profile.description = v.target.value;
              setProfile(profile);
            }}
          />
        </Form.Item>
      <Divider />
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
}
