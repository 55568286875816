import React from "react";
import {
  Col,
  Space,
  Spin,
  Card,
  Image,
  Tag,
  Rate,
} from "antd";
import { Link } from "react-router-dom";
import { Teacher } from "../../types/teacher";

interface TeacherCardProps {
  teacher: Teacher;
}

export function TeacherCard({ teacher }: TeacherCardProps) {
  return (
    <Col span={4} offset={1}>
      <Link to={`/teacher/${teacher.id}`}>
        <Space size={10}>
          <Card
            hoverable
            style={{ marginBottom: 20 }}
            cover={
              <Image
                preview={false}
                width={300}
                src={teacher.mainImage}
                placeholder={<Spin spinning={false} />}
              />
            }
          >
            <Card.Meta
              title={`${teacher.firstName} ${teacher.lastName}`}
              description={
                <div>
                <div>
                  <Tag color="green">{teacher.speciality.title}</Tag>
                </div>
                <div>
                  <Rate value={teacher.rate} disabled />
                </div>
                </div>
            }
            />
          </Card>
        </Space>
      </Link>
    </Col>
  );
}
