import {
  Typography,
} from "antd";
import React from "react";
import { Teacher } from "../../types/teacher";
import { Form, Input, Button } from "antd";

export interface ContactTeacherProps {
  teacher: Teacher;
}

export function ContactTeacher({ teacher }: ContactTeacherProps) {
  const layout = {
    labelCol   : { span: 8 },
    wrapperCol : { span: 16 },
  };

  return (
    <div>
      <Typography.Title>Записаться к преподавателю {teacher.firstName} {teacher.lastName}</Typography.Title>
      <Form {...layout} name="nest-messages">
        <Form.Item
          name={["user", "name"]}
          label="Ваше имя"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "email"]}
          label="Email"
          rules={[{ type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "phone"]}
          label="Телефон"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "introduction"]}
          label="Сопроводительное письмо"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
