import React from "react";
import {
  Button,
  Form,
  Input,
} from "antd";

export function FilterForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={onFinish}
    >
      <Form.Item
        name="fullName"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input placeholder="Поиск" />
      </Form.Item>
      <Form.Item shouldUpdate={true}>
        {() => (
          <Button type="primary" htmlType="submit">
            Искать
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}
