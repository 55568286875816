import {
  Image,
  Typography,
  Row,
  Col,
  Divider,
  Tabs,
  Rate,
  Carousel,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { arr, Gen } from "../../helpers";
import { Teacher } from "../../types/teacher";
import { TeacherHistory } from "./TeacherHistory";
import { TeacherComments } from "./TeacherComments";
import { TeacherNotes } from "./TeacherNotes";
import { ContactTeacher } from "./ContactTeacher";

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

interface TeacherPageURLParams {
  id: string;
}

export function TeacherPage() {
  let { id } = useParams<TeacherPageURLParams>();
  let teacher: Teacher = Gen.teacher(parseInt(id));

  return (
    <Row>
      <Col span={12} offset={6}>
        <Row>
          <Col span={24}>
            <Divider />
            <Carousel swipe dots effect="fade">
              {arr(4).map(() => (
                <div>
                  <Image
                    preview={false}
                    width={420}
                    src={Gen.loremFlickr()}
                    placeholder={
                      <Image
                        preview={false}
                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                        width={420}
                      />
                    }
                  />
                </div>
              ))}
            </Carousel>

            <Row>
              <Col span={24}>
                <Divider />
                <Title level={2}>
                  {teacher.firstName} {teacher.lastName}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
                <Rate value={7.5} allowHalf count={10} disabled />
                <Divider />
              </Col>
            </Row>
            <Row style={{ minHeight: 200 }}>
              <Col span={24}>
                <Tabs
                  defaultActiveKey="1"
                  onChange={() => {}}
                  type="card"
                  size="small"
                  centered
                  animated
                >
                  <TabPane tab="Информация" key="1">
                    <Paragraph>{teacher.description}</Paragraph>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        Отзывы <b>({teacher.comments.length})</b>
                      </span>
                    }
                    key="2"
                  >
                    <TeacherComments teacher={teacher} />
                  </TabPane>
                  <TabPane tab={`История обучения`} key="3">
                    <TeacherHistory teacher={teacher} />
                  </TabPane>
                  <TabPane tab={`Заметки`} key="4">
                    <TeacherNotes teacher={teacher} />
                  </TabPane>
                  <TabPane tab={`Записаться`} key="5">
                    <ContactTeacher teacher={teacher} />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col span={24}></Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
