import React from "react";
import { Layout, Tabs, Row, Col } from "antd";
import { Profile } from "./Profile/Profile";
import { Settings } from "./Settings/Settings";
import { MyTeachers } from "./MyTeachers/MyTeachers";
import { MyComments } from "./MyComments/MyComments";

const { Content } = Layout; //
const { TabPane } = Tabs; //

export function User() {
  return (
    <Row>
      <Col span={12} offset={6}>
        <Content style={{ padding: 10 }}>
          <Tabs defaultActiveKey="1" type="card" size="small" centered animated>
            <TabPane tab="Профиль" key="1">
              <Profile />
            </TabPane>

            <TabPane tab="Настройки" key="2">
              <Settings />
            </TabPane>

            <TabPane tab="Мои преподаватели" key="3">
              <MyTeachers />
            </TabPane>

            <TabPane tab="Мои отзывы" key="4">
              <MyComments />
            </TabPane>
          </Tabs>
        </Content>
      </Col>
    </Row>
  );
}
