import { Row, Col, Carousel, Image, Divider, Typography, Tabs, Layout } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { arr, Gen } from "../../helpers";
import { TeacherCard } from "./../Teachers/TeacherCard";

let {Content} = Layout;

interface CenterPageURLParams {
  id: string;
}

export function CenterPage() {
  let { id } = useParams<CenterPageURLParams>();
  let center = Gen.eduCenter(parseInt(id));
  return (
    <Row>
      <Col span={12} offset={6}>
        <Row>
          <Divider />
        </Row>
        <Row>
          <Col span={24}>
            <Carousel swipe dots effect="fade">
              {arr(4).map(() => (
                <div>
                  <Image
                    preview={false}
                    width={600}
                    src={Gen.loremFlickr(600, 400)}
                    placeholder={
                      <Image
                        preview={false}
                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                        width={420}
                      />
                    }
                  />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col>
                <Typography>
                  <Typography.Title>{center.title}</Typography.Title>
                  </Typography>
            <Divider />
            <Tabs type="card" defaultActiveKey="1" centered animated>
              <Tabs.TabPane tab="Информация" key="1">
                <Typography>
                  <Typography.Paragraph>
                    {center.description}
                  </Typography.Paragraph>
                </Typography>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span>Преподаватели <b>({center.teachers.length})</b></span>} key="2">
                <Content style={{ marginTop: 20 }}>
                  <Row>
                    {center.teachers.map((t) => (
                      <Col span={8}>
                        <TeacherCard teacher={t} />
                      </Col>
                    ))}
                  </Row>
                </Content>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
