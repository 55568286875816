import React from "react";
import {
  Layout,
  Row,
  Col,
  List,
  Divider,
  Pagination,
} from "antd";
import { Gen } from "../../helpers";
import { EduCenter } from "../../types/edu-center";
import { FilterForm } from "./FilterForm";
import { CenterCard } from "./CenterCard";

const { Content } = Layout; //

export function Centers() {
  let centers: EduCenter[] = Gen.eduCenters();

  return (
    <Row gutter={16}>
      <Col span={12} offset={6}>
        <Content style={{ padding: 20 }}>
          <Row>
            <Col span={20} offset={2}>
              <FilterForm />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Pagination defaultCurrent={1} total={50} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={centers}
                renderItem={center => <CenterCard center={center} />}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Pagination defaultCurrent={1} total={50} />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
}
