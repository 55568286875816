import faker from "faker";
import { Teacher } from "../types/teacher";
import { EduCenter } from "../types/edu-center";
import { Speciality } from "../types/speciality";

export function arr<T>(count: number) {
  return new Array<T>(count)
    .fill((void 0 as unknown) as T)
    .map((...[, idx]) => idx);
}

export function generateTeacherTimelineElement() {
  return {
    color    : faker.random.arrayElement(["green", "red", "gray"]),
    contents : faker.lorem.paragraph(),
    date     : faker.date.past().getFullYear(),
  };
}

export function generateTeacherNote() {
  return {
    contents: faker.lorem.paragraph(),
  };
}

export function generateSpeciality(id = 0): Speciality {
  return {
    id,
    title: faker.company.companyName(),
  };
}

export class Gen {
  public static teachers() {
    return arr(faker.random.number({ min: 10, max: 15 }))
      .map((...[, idx]) => idx)
      .map((...[, idx]) => this.teacher(idx));
  }

  public static specialities() {
    return arr(faker.random.number({ min: 10, max: 15 })).map((...[, idx]) =>
      generateSpeciality(idx)
    );
  }

  public static comments() {
    return arr(faker.random.number(20)).map(() => this.comment());
  }

  public static comment() {
    return {
      author  : faker.name.firstName(),
      content : faker.lorem.paragraph(),
      rate    : faker.random.number(5),
    };
  }

  public static teacher(id = 0): Teacher {
    return {
      id,
      firstName   : faker.name.firstName(),
      lastName    : faker.name.lastName(),
      mainImage   : Gen.loremFlickr(),
      speciality  : generateSpeciality(),
      description : faker.lorem.paragraph(faker.random.number(100)),
      comments    : arr(faker.random.number(20)).map(() => this.teacherComment()),
      timeline    : arr(faker.random.number(20)).map(() =>
        this.teacherTimelineElement()
      ),
      notes : arr(faker.random.number(20)).map(() => generateTeacherNote()),
      rate  : faker.random.number(5),
    };
  }
 
  public static teacherComment() {
    return {
      author  : faker.name.firstName(),
      content : faker.lorem.paragraph(),
      rate    : faker.random.number(5),
    };
  }

  public static teacherTimelineElement() {
    return {
      color    : faker.random.arrayElement(["green", "red", "gray"]),
      contents : faker.lorem.paragraph(),
      date     : faker.date.past().getFullYear(),
    };
  }

  public static eduCenter(id = 0): EduCenter {
    return {
      id,
      title       : faker.company.companyName(),
      description : faker.lorem.paragraph(),
      teachers    : this.teachers()
    };
  }

  public static eduCenters() {
    return arr(faker.random.number({ min: 10, max: 15 }))
      .map((...[, idx]) => idx)
      .map((...[, idx]) => this.eduCenter(idx));
  }

  public static loremFlickr(width = 200, height = 250) {
    return `https://loremflickr.com/${width}/${height}?rand=${
      Math.random() * 10000
    }`;
  }
}
