import { Gen } from "../../../helpers";
import { Teacher } from "../../../types/teacher";
import { TeacherCard } from "../../Teachers/TeacherCard";
import { Row, Col, Layout } from "antd";

const { Content } = Layout;

export function MyTeachers() {
  let teachers: Teacher[] = Gen.teachers();
  return (
    <Content style={{ marginTop: 20 }}>
      <Row>
        {teachers.map((t) => (
          <Col span={8}>
            <TeacherCard teacher={t} />
          </Col>
        ))}
      </Row>
    </Content>
  );
}
