import React from "react";
import { Gen } from "../../../helpers";
import { TeacherComment } from "../../../types/teacher";
import { Layout, List, Comment } from "antd";

const { Content } = Layout;

export function MyComments() {
  let comments: TeacherComment[] = Gen.comments();
  return (
    <Content style={{ marginTop: 20 }}>
    <List
      header={`${comments.length} replies`}
      itemLayout="horizontal"
      dataSource={comments}
      renderItem={(item) => (
        <li>
          <Comment
            author={item.author}
            avatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            content={<p>{item.content}</p>}
            datetime={"19 Dec 2020"}
          />
        </li>
      )}
    />
    </Content>
  );
}
