import { SettingOutlined, LogoutOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Avatar, Divider, Layout, Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Gen } from "../helpers";

const { Header } = Layout; //
const { SubMenu } = Menu;

export function HeadMenu() {
  return (
    <Header className="header">
      <div className="logo">LLLL.kz</div>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]}>
        <Menu.Item key="1">
          <Link to="/">Преподаватели</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/edu">Учебные центры</Link>
        </Menu.Item>
        <SubMenu
      key="SubMenu"
      icon={
        <span style={{ marginRight: 10 }}>
          <Avatar src={Gen.loremFlickr(100, 100)} size={40} />
        </span>
      }
      title={<span>Serabass <CaretDownOutlined /></span>}
      style={{ float: "right" }}
    >
      <Menu.Item key="setting:1">
        <SettingOutlined />
        <Link to="/user/profile">Профиль</Link>
      </Menu.Item>
      <Divider />
      <Menu.Item key="setting:4">
        <LogoutOutlined />
        Выйти
      </Menu.Item>
    </SubMenu>
      </Menu>
    </Header>
  );
}
